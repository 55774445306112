import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  withStyles,
  CardContent,
  Grid,
  Typography,
  CardActions,
  IconButton,
  Avatar,
} from "@material-ui/core";
import { blue, purple, lightGreen, red } from "@material-ui/core/colors";
import { GetData } from "../../api/service";
import ArrowForward from "@material-ui/icons/ArrowForward";
import { Alert, Button, Card, Progress, Tooltip, Tag } from "antd";
import { Chart } from "react-google-charts";

const styles = (theme) => ({
  root: {
    padding: "20px",
  },
  card: {
    boxShadow: "2px 2px 4px rgba(0,0,0,0.1), -2px -2px 4px rgba(0,0,0,0.2)",
    paddingLeft: "10px",
    borderRadius: "0px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    minHeight: "90px",
    borderRadius: "10px",
  },
  cardNumber: {
    fontSize: 40,
    fontWeight: "100",
    color: "inherit",
  },
  gridOwn: {
    display: "grid",
    gridTemplateColumns: "2fr 1fr",
    gridColumnGap: "20px",
    gridRowGap: "20px",
    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "1fr",
      marginTop: "-90px",
      paddingLeft: "20px",
      paddingRight: "20px",
    },
  },
  gridSmallLeft: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gridColumnGap: "20px",
    gridRowGap: "20px",
    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "1fr",
    },
  },
});

class Stats extends Component {
  state = {
    net_income: "0",
    total_income: "0",
    total_payout: "0",
    rank: "",
    calculation: {
      repurchase_wallet: "0",
      total_admin: "0",
      total_tds: "0",
    },
    burn_out_pairs: [],
    isLoaded: false,
    balance: 0,
    is_kyc_done: null,
    users: [],
    graphData: [],
    isGraphLoaded: false,
  };

  componentDidMount() {
    GetData(
      `/${this.props.apikey}/${this.props.username}/${this.props.username}/getuserincomenew`
    ).then((resp) => {
      this.setState({
        total_income: resp.total_income,
        burn_out_pairs: resp.burn_out_pairs,
        total_payout: resp.total_payout,
        net_income: resp.net_income,
        calculation: resp.calculation,
        rank: resp.rank,
        isLoaded: true,
      });
    });

    GetData(
      `/${this.props.apikey}/${this.props.username}/${this.props.username}/getuserbalance`
    ).then((resp) => {
      this.setState({
        balance: resp,
      });
    });

    GetData(`/${this.props.apikey}/getuserforsponsor`).then((resp) => {
      //console.log(resp);
      this.setState({
        users: resp,
      });
    });

    GetData(
      `/${this.props.apikey}/${this.props.username}/check-if-kyc-done`
    ).then((resp) => {
      //console.log(resp);
      this.setState({
        is_kyc_done: resp,
      });
    });
    GetData(`/mothlypaymentreport`).then((resp) => {
      console.log(resp);
      this.setState({
        graphData: resp,
        isGraphLoaded: true,
      });
    });
  }

  render() {
    //console.log(this.state.users);
    let { classes } = this.props;
    return (
      <div className={classes.root}>
        <Card
          style={{
            backgroundImage: `url('/images/icons/particlesjs-examples.gif')`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            height: 130,
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <div>
              <Avatar
                src={`/images/icons/user.png`}
                style={{
                  backgroundColor: "white",
                  border: "solid 2px white",
                }}
              />
            </div>
            <div style={{ paddingLeft: 20, color: "white" }}>
              <Typography variant="h6">
                Welcome !! <br />
                {this.props.user_data.name} [{this.props.user_data.user_id}]
              </Typography>
            </div>
          </div>
        </Card>

        <br />

        <br />

        <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={4} lg={4}>
            <Card>
              {/* <Typography gutterBottom variant="h6">
                My Tree Statistics
              </Typography>
              <br /> */}
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <Card style={{ backgroundColor: "#DC53CF" }}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <div style={{ flexBasis: "30%" }}>
                        <Avatar
                          src={`/images/icons/team.png`}
                          style={{
                            backgroundColor: "white",
                            border: "solid 2px white",
                          }}
                        />
                      </div>
                      <div style={{ flexBasis: "70%" }}>
                        <Typography
                          variant="overline"
                          style={{ color: "white" }}
                        >
                          TOTAL MEMBERS
                        </Typography>
                        <Typography variant="h6" style={{ color: "white" }}>
                          {this.state.users.total}
                        </Typography>
                      </div>
                    </div>
                  </Card>
                </Grid>

                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <Card style={{ backgroundColor: "#474554" }}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <div style={{ flexBasis: "30%" }}>
                        <Avatar
                          src={`/images/icons/team.png`}
                          style={{
                            backgroundColor: "white",
                            border: "solid 2px white",
                          }}
                        />
                      </div>
                      <div style={{ flexBasis: "70%" }}>
                        <Typography
                          variant="overline"
                          style={{ color: "white" }}
                        >
                          DIRECT MEMBERS
                        </Typography>
                        <Typography variant="h6" style={{ color: "white" }}>
                          {this.state.users.direct}
                        </Typography>
                      </div>
                    </div>
                  </Card>
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Card
                    style={{
                      backgroundColor: "#0093C8",
                      color: "white",
                      marginTop: "20px",
                    }}
                  >
                    <CardContent>
                      <Typography className={classes.cardNumber}>
                        {this.state.users.downline_l} /{" "}
                        {this.state.users.downline_r}
                      </Typography>
                      <Typography
                        variant="subheading"
                        color="inherit"
                        className={classes.cardNumberText}
                      >
                        Total Left / Total Right
                      </Typography>
                      <br />
                      <br />

                      <Typography className={classes.cardNumber}>
                        {this.state.users.downline_l_a} /{" "}
                        {this.state.users.downline_r_a}
                      </Typography>
                      <Typography
                        variant="subheading"
                        color="inherit"
                        className={classes.cardNumberText}
                      >
                        Total Left Active / Total Right Active
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                {/* <Grid item xs={12} sm={12} md={6} lg={6}>
                  <Card style={{ textAlign: "center" }}>
                    <Tooltip title="Left Progress">
                      <Progress
                        showInfo={true}
                        percent={parseInt(this.state.users.p_l)}
                        type="dashboard"
                      />
                    </Tooltip>
                    <br />
                    <Typography variant="overline">
                      LEFT ACTIVE PERCENTAGE
                    </Typography>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <Card style={{ textAlign: "center" }}>
                    <Tooltip title="Left Progress">
                      <Progress
                        showInfo={true}
                        percent={parseInt(this.state.users.p_r)}
                        type="dashboard"
                      />
                    </Tooltip>
                    <br />
                    <Typography variant="overline">
                      RIGHT ACTIVE PERCENTAGE
                    </Typography>
                  </Card>
                </Grid> */}
                {/* {this.state.burn_out_pairs.length > 0 && <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Card style={{ textAlign: "center" }}>
                    <Typography variant="h6" gutterBottom style={{fontWeight: 700}}>BURNOUT PAIRS</Typography>


                    {this.state.burn_out_pairs.map((el,index) => <Tag color="#D35520" key={index}>{el}</Tag>)}

                  </Card>
                </Grid>} */}
              </Grid>
            </Card>
          </Grid>

          <Grid item xs={12} sm={12} md={8} lg={8}>
            <Card>
              {/* <Typography gutterBottom variant="h6">
                My Income Statistics
              </Typography>
              <br /> */}
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <Card style={{ backgroundColor: "#5564EE" }}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <div style={{ flexBasis: "20%" }}>
                        <Avatar
                          src={`/images/icons/salary.png`}
                          style={{
                            backgroundColor: "white",
                            border: "solid 2px white",
                          }}
                        />
                      </div>
                      <div style={{ flexBasis: "70%" }}>
                        <Typography
                          variant="overline"
                          style={{ color: "white" }}
                        >
                          TOTAL INCOME
                        </Typography>
                        <Typography variant="h6" style={{ color: "white" }}>
                          ₹ {this.state.total_income}
                        </Typography>
                      </div>
                      <div style={{ flexBasis: "10%" }}>
                        <Link to="/income">
                          <IconButton>
                            <ArrowForward style={{ color: "white" }} />
                          </IconButton>
                        </Link>
                      </div>
                    </div>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <Card style={{ backgroundColor: "#007CFA" }}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <div style={{ flexBasis: "20%" }}>
                        <Avatar
                          src={`/images/icons/salary.png`}
                          style={{
                            backgroundColor: "white",
                            border: "solid 2px white",
                          }}
                        />
                      </div>
                      <div style={{ flexBasis: "70%" }}>
                        <Typography
                          variant="overline"
                          style={{ color: "white" }}
                        >
                          NET INCOME
                        </Typography>
                        <Typography variant="h6" style={{ color: "white" }}>
                          ₹{" "}
                          {parseFloat(
                            parseFloat(this.state.total_income) -
                              (parseFloat(this.state.calculation.total_admin) +
                                parseFloat(this.state.calculation.total_tds)) -
                              parseFloat(
                                this.state.calculation.repurchase_wallet
                              )
                          ).toFixed(2)}
                        </Typography>
                      </div>
                      <div style={{ flexBasis: "10%" }}>
                        {/* <Link to="/income">
                          <IconButton>
                            <ArrowForward style={{ color: "white" }} />
                          </IconButton>
                        </Link> */}
                      </div>
                    </div>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <Card style={{ backgroundColor: "#27AE60" }}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <div style={{ flexBasis: "20%" }}>
                        <Avatar
                          src={`/images/icons/salary.png`}
                          style={{
                            backgroundColor: "white",
                            border: "solid 2px white",
                          }}
                        />
                      </div>
                      <div style={{ flexBasis: "70%" }}>
                        <Typography
                          variant="overline"
                          style={{ color: "white" }}
                        >
                          PAID PAYOUT
                        </Typography>
                        <Typography variant="h6" style={{ color: "white" }}>
                          ₹ {this.state.total_payout}
                        </Typography>
                      </div>
                      <div style={{ flexBasis: "10%" }}>
                        <Link to="/income">
                          <IconButton>
                            <ArrowForward style={{ color: "white" }} />
                          </IconButton>
                        </Link>
                      </div>
                    </div>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <Card style={{ backgroundColor: "#D35400" }}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <div style={{ flexBasis: "20%" }}>
                        <Avatar
                          src={`/images/icons/salary.png`}
                          style={{
                            backgroundColor: "white",
                            border: "solid 2px white",
                          }}
                        />
                      </div>
                      <div style={{ flexBasis: "70%" }}>
                        <Typography
                          variant="overline"
                          style={{ color: "white" }}
                        >
                          BALANCE PAYOUT
                        </Typography>
                        <Typography variant="h6" style={{ color: "white" }}>
                          ₹{" "}
                          {parseFloat(
                            parseFloat(
                              parseFloat(this.state.total_income) -
                                (parseFloat(
                                  this.state.calculation.total_admin
                                ) +
                                  parseFloat(
                                    this.state.calculation.total_tds
                                  )) -
                                parseFloat(
                                  this.state.calculation.repurchase_wallet
                                )
                            ) - parseFloat(this.state.total_payout)
                          ).toFixed(2)}
                        </Typography>
                      </div>
                      <div style={{ flexBasis: "10%" }}>
                        {/* <Link to="/income">
                          <IconButton>
                            <ArrowForward style={{ color: "white" }} />
                          </IconButton>
                        </Link> */}
                      </div>
                    </div>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <Card style={{ backgroundColor: "#633974" }}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <div style={{ flexBasis: "20%" }}>
                        <Avatar
                          src={`/images/icons/salary.png`}
                          style={{
                            backgroundColor: "white",
                            border: "solid 2px white",
                          }}
                        />
                      </div>
                      <div style={{ flexBasis: "70%" }}>
                        <Typography
                          variant="overline"
                          style={{ color: "white" }}
                        >
                          TDS + ADMIN
                        </Typography>
                        <Typography variant="h6" style={{ color: "white" }}>
                          ₹{" "}
                          {parseFloat(
                            parseFloat(this.state.calculation.total_admin) +
                              parseFloat(this.state.calculation.total_tds)
                          ).toFixed(2)}
                        </Typography>
                      </div>
                      <div style={{ flexBasis: "10%" }}>
                        {/* <Link to="/income">
                          <IconButton>
                            <ArrowForward style={{ color: "white" }} />
                          </IconButton>
                        </Link> */}
                      </div>
                    </div>
                  </Card>
                </Grid>

                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <Card style={{ backgroundColor: "#2C3E50" }}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <div style={{ flexBasis: "20%" }}>
                        <Avatar
                          src={`/images/icons/salary.png`}
                          style={{
                            backgroundColor: "white",
                            border: "solid 2px white",
                          }}
                        />
                      </div>
                      <div style={{ flexBasis: "70%" }}>
                        <Typography
                          variant="overline"
                          style={{ color: "white" }}
                        >
                          REPURCHASE WALLET PAID
                        </Typography>
                        <Typography variant="h6" style={{ color: "white" }}>
                          ₹{" "}
                          {parseFloat(
                            parseFloat(
                              this.state.calculation.repurchase_wallet
                            ) -
                              parseFloat(
                                this.state.calculation.repurchase_wallet_balance
                              )
                          ).toFixed(2)}
                        </Typography>
                      </div>
                      <div style={{ flexBasis: "10%" }}>
                        {/* <Link to="/income">
                          <IconButton>
                            <ArrowForward style={{ color: "white" }} />
                          </IconButton>
                        </Link> */}
                      </div>
                    </div>
                  </Card>
                </Grid>

                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <Card style={{ backgroundColor: "#009399" }}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <div style={{ flexBasis: "20%" }}>
                        <Avatar
                          src={`/images/icons/salary.png`}
                          style={{
                            backgroundColor: "white",
                            border: "solid 2px white",
                          }}
                        />
                      </div>
                      <div style={{ flexBasis: "70%" }}>
                        <Typography
                          variant="overline"
                          style={{ color: "white" }}
                        >
                          REPURCHASE WALLET BALANCE
                        </Typography>
                        <Typography variant="h6" style={{ color: "white" }}>
                          ₹{" "}
                          {parseFloat(
                            this.state.calculation.repurchase_wallet_balance
                          ).toFixed(2)}
                        </Typography>
                      </div>
                      <div style={{ flexBasis: "10%" }}>
                        {/* <Link to="/income">
                          <IconButton>
                            <ArrowForward style={{ color: "white" }} />
                          </IconButton>
                        </Link> */}
                      </div>
                    </div>
                  </Card>
                </Grid>

                
              </Grid>
            </Card>
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12}>
          {this.state.isGraphLoaded == true && (
                  <>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <Chart
                        chartType="PieChart"
                        data={[
                          ["Type", "Amount"],
                          [
                            "Net Income",
                            parseFloat(
                              parseFloat(this.state.total_income) -
                                (parseFloat(
                                  this.state.calculation.total_admin
                                ) +
                                  parseFloat(
                                    this.state.calculation.total_tds
                                  )) -
                                parseFloat(
                                  this.state.calculation.repurchase_wallet
                                )
                            ),
                          ],
                          [
                            "Admin",
                            parseFloat(this.state.calculation.total_admin),
                          ],
                          ["TDS", parseFloat(this.state.calculation.total_tds)],
                          [
                            "Repurchase Income",
                            parseFloat(
                              this.state.calculation.repurchase_wallet
                            ),
                          ],
                        ]}
                        options={{
                          title: "",
                          is3D: true,
                        }}
                        width={"100%"}
                        height={"250px"}
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <Chart
                        chartType="ColumnChart"
                        width="100%"
                        height="400px"
                        data={this.state.graphData}
                      />
                    </Grid>
                  </>
                )}
          </Grid>
        </Grid>

        <br />
        <br />

        {this.state.is_kyc_done == 0 && (
          <Alert
            message="KYC Not done"
            showIcon
            description={`You have not verified to your KYC, you can click on the button to complete your verification`}
            type="error"
            action={
              <Link to="/profile">
                <Button size="small" danger>
                  Verify Account
                </Button>
              </Link>
            }
          />
        )}

        {/* {this.state.is_kyc_done == 1 && (
          <Alert
            message="KYC Verification Successful"
            showIcon
            description={`Your KYC has been verfied successfully.`}
            type="success"
          />
        )} */}
      </div>
    );
  }
}

export default withStyles(styles)(Stats);

export const DetailsCard = (props) => {
  let { classes } = props;
  return (
    <React.Fragment>
      <Card
        className={classes.card}
        style={{ backgroundColor: props.background, color: props.color }}
      >
        <CardContent>
          <Typography className={classes.cardNumber}>{props.value}</Typography>
          <Typography
            variant="subheading"
            color="inherit"
            className={classes.cardNumberText}
          >
            {props.title}
          </Typography>
        </CardContent>

        {props.action ? (
          <CardContent
            style={{ justifyContent: "flex-end", alignItems: "center" }}
          >
            <Link to="/income">
              <IconButton>
                <ArrowForward style={{ color: "white" }} />
              </IconButton>
            </Link>
          </CardContent>
        ) : null}
      </Card>
    </React.Fragment>
  );
};
